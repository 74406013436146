/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

const React = require('react');

function renderHelp() {
    //return (<div>Help</div>);
    // const myEle = <div className="HelpDiv">
    //         <p><img src="assets/img/logoherne.png" width="200" /></p>
    //         <p>Kennen Sie noch weitere klimafreundliche Orte in Herne? Melden Sie sich doch gerne bei uns.</p>
    //         <p><a href="https://herne.de/klima/index.html" target="_blank">Stadt Herne</a><br/>Tel.: 02323 161618<br/>fb-umweltundstadtplanung@herne.de</p>
    //         </div>;
    const myEle = <div className="HelpDiv">      
            <details>
            <summary className="DetailSum">Technischer Ansprechpartner</summary>
            <p>Bei technischen Fragen zu Geodaten oder den Themenkarten, nehmen Sie bitte Kontakt zu uns auf:</p>
            <p>Stadt Herne<br/>Fachbereich Vermessung und Kataster<br/>Langekampstraße 36<br/>44652 Herne<br/><a href="mailto:fb-vermessung-und-kataster@herne.de">fb-vermessung-und-kataster@herne.de</a></p>
            </details><hr></hr>
            {/* <details>
            <summary className="DetailSum">Nutzungsbedingungen</summary>
            <p>Infotext ...</p>
            </details><hr></hr> */}
            <details>
            <summary className="DetailSum">Bedienungshilfe</summary>
            <p>Einen Überblick über die wesentlichen Komponenten und Funktionen des Geoportals gibt Ihnen die nachfolgende kurze Anleitung:<br/>
            <a href="assets/anleitung/index.html" target="_blank">Anleitung</a></p>
            </details>
            </div>;
    return (myEle);
}

module.exports = renderHelp;
